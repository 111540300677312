<template>
    <div id="index">
      <dv-full-screen-container class="bg">
        <div class="host-body">
          <!-- 第二行 -->
          <Menu name="video-aerial-photo" style="background: black;z-index: 999;position: relative;" @change="onProInfoChange($event)"/>
          <div id="content" >
              
          </div>
        </div>
      </dv-full-screen-container>
    </div>
  </template>
  
  <script>
  import Menu from "@/components/Menu";
  import ProInfoCache from "@/utils/cache/ProInfoCache"
  
  moment.locale("zh-cn");
  import moment from "moment";
  export default {
    data() {
      return {
        loading: true,
        pro:{},
      };
    },
    components: {
      Menu,
    },
    created: function () {
      this.loading=false
    },
    mounted() {
        this.pro = ProInfoCache.getProInfo()
    },
    watch: {
    },
    methods: {
    },
  };
  </script>
  <style scoped>
  
  </style>